<template>
  <div>
    <h3 class="pb-2">
      Attribute Management
      <hr />
    </h3>
    <div v-if="!loading">
      <div v-if="!attribute">
        <div class="card">
          <div class="card-header">
            <div class="card-title mb-0">Attributes</div>
          </div>
          <div class="card-body">
            <div class="d-flex mb-2">
              <div class="align-self-center">Items Per Page:</div>
              <div class="ml-2">
                <b-form-select
                  v-model="perPage"
                  id="perPageSelect"
                  size="sm"
                  :options="pageOptions"
                  aria-label="Items Per Page"
                ></b-form-select>
              </div>
            </div>
            <b-table
              ref="table"
              striped
              :fields="fields"
              :items="tableAttributes"
              :current-page="currentPage"
              :per-page="perPage"
              stacked="md"
              small
              class="mt-4"
              bordered
              sort-icon-left
            >
              <template v-slot:cell(id)="data">
                <button
                  class="btn btn-primary btn-sm mr-2"
                  @click="view(data.item.id)"
                >
                  View
                </button>
              </template>
            </b-table>
            <div class="d-flex">
              <div class="mr-auto">
                Showing
                {{ perPage * currentPage - perPage + (totalRows > 0 ? 1 : 0) }}
                to {{ perPage * currentPage - perPage + perPageDisplay }} of
                {{ totalRows }} entries
              </div>
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="card" v-else>
        <div class="card-header">
          <div class="card-title mb-0">Attribute</div>
        </div>
        <div class="card-body">
          <form>
            <div class="row">
              <div class="col-12">
                <TextInput
                  name="Attribute Name"
                  id="name"
                  v-model="attribute.globalAttribute.name"
                  disabled
                />
                <small class="form-text text-muted mb-2"
                  >The name for the attribute.</small
                >
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <label for="isSearchable">Searchable</label>
                <select
                  class="form-control form-control-lg"
                  id="isSearchable"
                  v-model="attribute.isSearchable"
                  disabled
                >
                  <option
                    v-for="(stat, index) in searchableStatues"
                    :key="index"
                    :value="stat.value"
                    aria-placeholder="Select Searchable..."
                    >{{ stat.name }}</option
                  >
                </select>

                <small class="form-text text-muted mb-2">
                  Whether or not the attribute shows in filtering functions on
                  the website.
                </small>
              </div>
              <div class="col-md-6 col-sm-12">
                <label for="showIcon">Show Icon</label>
                <select
                  class="form-control form-control-lg"
                  id="showIcon"
                  v-model="attribute.showIcon"
                  disabled
                >
                  <option
                    v-for="(stat, index) in searchableStatues"
                    :key="index"
                    :value="stat.value"
                    aria-placeholder="Select Show Icon..."
                    >{{ stat.name }}</option
                  >
                </select>

                <small class="form-text text-muted mb-2">
                  Whether or not the attribute icon shows on the website.
                </small>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="Attribute Type"
                  id="name"
                  v-model="attribute.globalAttribute.attributeValueType.name"
                  disabled
                />
                <small class="form-text text-muted mb-2"
                  >The name for the attribute type.</small
                >
              </div>
              <div class="col-md-6 col-sm-12">
                <TextInput
                  name="Attribute Icon"
                  id="icon"
                  v-model="attribute.globalAttribute.icon"
                  disabled
                />
                <small class="form-text text-muted mb-2"
                  >The icon for the amenity.</small
                >
              </div>
            </div>
            <div v-if="attribute.attributeOptions.length > 0">
              <b-table
                ref="table"
                striped
                :fields="optionsFields"
                :items="attribute.attributeOptions"
                stacked="md"
                small
                class="mt-4"
                bordered
                sort-icon-left
              >
                <template v-slot:cell(id)="data">
                  <button
                    class="btn btn-primary btn-sm mr-2"
                    @click="view(data.item.id)"
                  >
                    View
                  </button>
                </template>
              </b-table>
            </div>
            <button
              class="btn-cancel btn mt-4 mr-2"
              @click="cancel"
              type="button"
            >
              Cancel
            </button>
          </form>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import AdminTenantService from "@/services/admin/AdminTenantService.js";
import TextInput from "@/validation/TextInput.vue";
export default {
  name: "AdminAttributeManagement",
  title: "Admin - Attribute Management",
  components: {
    TextInput
  },
  data() {
    return {
      loading: false,

      attribute: null,
      attributes: [],
      attributeCategories: [],
      searchableStatues: [
        { name: "Yes", value: true },
        { name: "No", value: false }
      ],
      fields: [
        { key: "name", sortable: true, label: "Amenity Name" },
        {
          key: "attributeValueType",
          sortable: true,
          label: "Attribute Type"
        },
        { key: "searchable", sortable: true, label: "Searchable" },
        { key: "spotOverride", sortable: true, label: "Overridable at Spot" },
        { key: "id", label: "Actions/Options", class: "actionsColumn" }
      ],
      optionsFields: [
        { key: "value", sortable: true, label: "Attribute Options" }
      ],
      viewOnly: false,
      currentPage: 1,
      totalRows: 25,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      perPageDisplay: 10
    };
  },
  methods: {
    async initialize() {
      this.loading = true;
      const service = new AdminTenantService(this.tenantId);
      const response = await service.getAttributes({
        isSearchable: false
      });
      if (response?.statusCode === "Success") {
        this.attributes = response.data;
        this.totalRows = this.attributes.length;
        this.loading = false;
      } else {
        this.loading = false;
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      }
    },
    view(id) {
      this.viewOnly = true;
      this.attribute = {
        ...this.attributes.find(x => x.id === id)
      };
    },
    cancel() {
      this.viewOnly = false;
      this.attribute = null;
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    tableAttributes() {
      const tableAttributes = [];
      this.attributes.forEach(x => {
        tableAttributes.push({
          id: x.id,
          name: x.globalAttribute.name,
          attributeValueType: x.globalAttribute.attributeValueType.name,
          searchable: x.isSearchable ? "Yes" : "No",
          spotOverride: x.overridableAtSpot ? "Yes" : "No"
        });
      });
      return tableAttributes;
    }
  },
  created() {
    this.initialize();
  }
};
</script>

<style></style>
